import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Spinner } from "react-bootstrap";
import { useCurrentWidth } from "react-socks";
import axios from "axios";

const AboutUsNavigation = lazy(() => import("./AboutUsNavigation"));
const CeoCornerNavigation = lazy(() => import("./CeoCornerNavigation"));
const customHistory = lazy(() => import("customHistory"));

const NavBar = lazy(() => import("components/NavBar"));
const Explore = lazy(() => import("screens/Home/Explore"));
const Home = lazy(() => import("screens/Home"));
const HomeCandidateNavigation = lazy(() =>
  import("navigation/HomeNavigation/HomeCandidateNavigation")
);
const HomeRecruiterNavigation = lazy(() =>
  import("navigation/HomeNavigation/HomeRecruiterNavigation")
);

const ContactForm = lazy(() => import("components/ContactForm"));
const Rocket = lazy(() => import("screens/Landing/Rocket"));
const JobPost = lazy(() => import("screens/Landing/JobPost"));
const Landing = lazy(() => import("screens/Landing"));
const Blogs = lazy(() => import("screens/Blogs"));
const Blog = lazy(() => import("screens/Blog"));
const PageNotFound = lazy(() => import("screens/PageNotFound"));
const MaintenanceMode = lazy(() => import("screens/MaintenanceMode"));

const Navigation = (props) => {
  console.log(props);

  const count = useSelector((state) => state.counters);
  const width = useCurrentWidth();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const renderLoader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );

  return (
    <Suspense fallback={renderLoader()}>
      <Router>
        <Container
          className="p-0 d-flex flex-column"
          fluid
          style={styles.parentContainer}
        >
          {process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
            <Container className="p-0 h-100" fluid>
              <Switch>
                <Route path="*">
                  <MaintenanceMode />
                </Route>
              </Switch>
            </Container>
          ) : (
            <>
              <NavBar />
              <Container className="p-0 h-100" fluid>
                <Switch>
                  <Route path="/about-us/" component={AboutUsNavigation} />
                  <Route path="/blogs/:slug" component={Blog} />
                  <Route path="/blogs" component={Blogs} />
                  <Route path="/ceo-corner/" exact component={CeoCornerNavigation} />
                  <Route path="/explore" component={Explore} />
                  <Route path="/home">
                    {count.currentMode === "candidate" ? (
                      <HomeCandidateNavigation />
                    ) : (
                      <HomeRecruiterNavigation />
                    )}
                  </Route>
                  <Route path="/get-in-touch">
                    <Container fluid className="pt-5">
                      <ContactForm pageType={"aboutUs"} is_light={false} page="Candidate" />
                    </Container>
                  </Route>
                  <Route path="/contact-us">
                    <ContactForm pageType={"aboutUs"} is_light={false} page="Candidate" />
                  </Route>
                  <Route path="/landing/apply/:slug" component={JobPost} />
                  <Route path="/landing/applying/:slug" component={Rocket} />
                  <Route path="/landing/:slug" component={Landing} />
                  <Route exact path="/" component={Home} />
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Container>
            </>
          )}
        </Container>
      </Router>
    </Suspense>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Navigation;
